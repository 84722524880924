@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'BeausiteClassicRegular';
    font-display: swap;
    src: url('/fonts/BeausiteClassicWeb-Regular.woff2') format('woff2'),
      url('/fonts/BeausiteClassicWeb-Semibold.woff2') format('woff2'),
      url('/fonts/BeausiteClassicWeb-Regular.woff') format('woff'),
      url('/fonts/BeausiteClassicWeb-Semibold.woff') format('woff');
  }
  @font-face {
    font-family: 'BeausiteClassicSemibold';
    font-display: swap;
    src: url('/fonts/BeausiteClassicWeb-Semibold.woff2') format('woff2'),
      url('/fonts/BeausiteClassicWeb-Semibold.woff') format('woff');
  }
  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-2xl;
  }
  h4 {
    @apply text-xl;
  }
  body {
    @apply overflow-auto bg-tella-light-pink-200;
  }
}

:root {
  --blue-300: #10069f;
  --blue-100: rgba(16, 6, 159, 0.1);
  --pink-300: #ef426f;
  --light-pink-300: #f7ced7;
  --light-pink-200: #fcebef;
  --light-pink-100: #fdf5f7;
  --light-purple-100: #f2f2f9;
  --light-yello-500: #f7aa23;
  --dark-gray: #1c1c1c;
  --green-300: #37c49f;
}

.font-semibold {
  font-family: 'BeausiteClassicSemibold';
  font-weight: 100;
}

input,
input[type='text'],
input [type='date'],
input [type='date']:focus,
input[type='number']:focus,
input[type='text']:focus {
  box-shadow: none;
  outline: none;
}

.opacity-gradient {
  height: 120px;
  background: linear-gradient(to bottom, rgb(255, 255, 255), rgba(0, 0, 0, 0));
}
/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[type='number'] {
  -moz-appearance: textfield;
} */

.w-18 {
  width: 4.5rem;
}

.tooltip-container {
  --tooltipBackground: var(--blue-300) !important;
  --tooltipBorder: var(--blue-300) !important;
  @apply max-w-xs rounded-md px-2 py-1 text-sm text-white md:max-w-lg !important;
}

.checkbox__rounded input[type='checkbox']:checked {
  box-shadow: inset 0 0 0 20px var(--blue-300);
}

.rich-text h1 {
  border: #ef426f dashed 10px;
  color: #ef426f;
  padding: 1rem;
  font-size: 60px;
}
.rich-text h1,
.rich-text h2,
.rich-text h3,
.rich-text h4 {
  @apply mb-6 font-semibold;
}
.rich-text p {
  @apply mb-6;
}
.rich-text ul {
  @apply mb-6 list-disc pl-5;
}
.rich-text ol {
  @apply mb-6 list-decimal pl-5;
}
.rich-text li {
  @apply mb-1;
}
.rich-text table {
  @apply mb-6 w-full;
}
.rich-text thead {
  @apply bg-tella-blue-300 text-tella-light-pink-200;
}
.rich-text th,
td {
  @apply p-2 text-left;
}
.rich-text tr:nth-child(even) {
  background-color: var(--blue-100);
}
.rich-text a {
  @apply text-tella-pink-300 underline;
}
.rich-list-level-1,
.rich-list-level-2 {
  @apply mb-2 flex;
}
.rich-list-level-2 {
  @apply pl-7;
}
.rich-list-level-1 span {
  @apply pr-2;
}
.rich-list-level-2 span {
  @apply pr-3;
}

.rates-card-container {
  @apply flex flex-col gap-2 pb-6;
}

input:checked ~ .dot {
  transform: translateX(100%);
}

.mortgageBackground {
  background-color: var(--light-purple-100);
}

.review-card {
  background-color: var(--light-purple-100);
}

.review-star {
  color: var(--light-yello-500);
}

.img-wrapper {
  background-color: var(--light-pink-100);
}

.img-wrapper img {
  filter: grayscale(100%) contrast(1);
  mix-blend-mode: inherit;
  width: 100%;
}

.img-wrapper::before {
  background-color: var(--blue-300);
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  mix-blend-mode: screen;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.ios-scroll {
  -webkit-overflow-scrolling: touch;
}

.h-full100 {
  height: 100% !important;
}

/* Override react-slick carousel styling */
.slick-dots li.slick-active button:before {
  @apply text-tella-blue-300 !important;
}
.slick-prev,
.slick-next {
  @apply z-10 hidden sm:block;
}
.slick-prev {
  left: 3% !important;
}
.slick-next {
  right: 3% !important;
}
.slick-prev:before,
.slick-next:before {
  @apply hidden text-tella-light-pink-300 sm:block !important;
}
